<template>
	<el-card class="box-card">
		<el-row :gutter="12" class="center">
			<!-- <div class="sub-title">{{$t('account.profile.mcc-subtitle')}}</div> -->
			<el-col :xs="24" :sm="24" :md="8" :lg="8">
				<el-input v-model="mccFilter" class="input-with-select"
					:placeholder="$t('account.profile.mcc-placeholder1')" @keyup.enter.native="getMCC()" size="small">
					<el-button class="b-c-gm" slot="append" icon="el-icon-search" @click.native="getMCC()">
					</el-button>
				</el-input>
			</el-col>
			<el-col :xs="24" :sm="24" :md="8" :lg="8">
				<el-input v-model="mccFilterDescription" class="input-with-select"
					:placeholder="$t('account.profile.mcc-placeholder2')" @keyup.enter.native="getMCC" size="small">
					<el-button class="b-c-gm-2" slot="append" icon="el-icon-search" @click.native="getMCC()">
					</el-button>
				</el-input>
			</el-col>
			<el-col :xs="24" :sm="24" :md="8" :lg="8">
				<el-input v-model="mccFilterDescriptionRo" class="input-with-select"
					:placeholder="$t('account.profile.mcc-placeholder3')" @keyup.enter.native="getMCC" size="small">
					<el-button class="b-c-gm-3" slot="append" icon="el-icon-search" @click.native="getMCC()">
					</el-button>
				</el-input>
			</el-col>
		</el-row>
		<div class="separator"></div>
		<div>
			<div class="center">
				<el-pagination layout="prev, pager, next, total" :current-page="page" :total="total" :page-size="limit"
					@current-change="changePage">
				</el-pagination>
			</div>
			<el-table id="mccList" :data="mccData" stripe size="mini" style="width: 100%" default-expand-all>
				<el-table-column type="expand">
					<template slot-scope="props">
						<p><strong>{{$t('account.profile.mcc-label4')}}</strong>: {{ props.row.detalii }}</p>
						<p><strong>{{$t('account.profile.mcc-label5')}}</strong>: {{ props.row.detalii_romana }}</p>
					</template>
				</el-table-column>
				<el-table-column prop="mcc" label="MCC" width="50"></el-table-column>
				<el-table-column prop="tcc" label="TCC" width="75"></el-table-column>
				<el-table-column prop="tip_flux_preaprobare" :label="$t('account.profile.mcc-label3')" width="150">
				</el-table-column>
				<el-table-column prop="descriere_categorie" :label="$t('account.profile.mcc-label1')">
				</el-table-column>
				<el-table-column prop="descriere_categorie_romana" :label="$t('account.profile.mcc-label2')">
				</el-table-column>
				<el-table-column :label="$t('account.profile.mcc-label6')" width="90">
					<template slot-scope="scope">
						<div v-if="isAdded(scope.row.mcc)">
							<el-button class="b-c-rm" size="mini" type="danger"
								@click.native="removeMCC(scope.row.mcc)">
								{{$t('general.delete')}}</el-button>
						</div>
						<div v-else>
							<el-button class="b-c-am" size="mini" type="success" @click.native="addMCC(scope.row.mcc)">
								{{$t('general.add')}}</el-button>
						</div>
					</template>
				</el-table-column>
			</el-table>
			<div class="center">
				<el-pagination layout="prev, pager, next, total" :current-page="page" :total="total" :page-size="limit"
					@current-change="changePage">
				</el-pagination>
			</div>
		</div>
	</el-card>
</template>

<script>
	import {
		get,
		sync,
		call
	} from 'vuex-pathify';
	export default {
		data: () => ({

		}),
		computed: {
			...sync('account/informations@mcc.list', {
				mccData: 'data',
				page: 'page',
				limit: 'limit',
				total: 'total'
			}),
			...sync('account/informations@mcc.list.filters', {
				mccFilterDescription: 'descriere',
				mccFilterDescriptionRo: 'descriere_romana',
				mccFilter: 'mcc'
			}),
			selectedMCC: get('account/informations@mcc.selected')
		},
		methods: {
			getMCC: call('account/GetAffiliateMCCData'),
			removeMCC: call('account/RemoveAffiliateMCC'),
			addMCC: call('account/AddAffiliateMCC'),
			async changePage(page) {
				this.page = page;
				try {
					await this.getMCC();
				} catch (err) {
					this.$reportToSentry(err, {
						extra: {
							fn: 'getMCC'
						}
					});
				}
			},
			isAdded(mcc) {
				return this.selectedMCC.includes(mcc);
			},

		},
		async mounted() {
			try {
				await this.getMCC();
			} catch (err) {
				this.$reportToSentry(err, {
					extra: {
						fn: 'getMCC'
					}
				});
			}
		}
	};
</script>

<style>

</style>